<template>
	<div class="w-100">
		<vertical-menu :menu-tree="menuTreeNoChildren"></vertical-menu>
		<tiles-menu :menu-tree="menuTree" style="margin-left: 260px"></tiles-menu>
	</div>
</template>

<script>
	import VerticalMenu from "@/views/layout/app-sidebar-menu/components/VerticalMenu";
	import TilesMenu from "@/views/layout/app-sidebar-menu/components/TilesMenu";
	import { computed, reactive, toRefs } from "@vue/composition-api";

	export default {
		name: "ExpandMenu",
		props: ["menuTree"],
		components: {
			VerticalMenu,
			TilesMenu,
		},
		setup(props) {
			/**
			 * 优先读取store中的菜单列表
			 * 张强 2021-08-09
			 */
			const event = reactive({
				menuTreeNoChildren: computed(() => {
					let menuTree = JSON.parse(JSON.stringify(props.menuTree));
					menuTree.map((menu) => {
						if (menu.children && menu.children.length > 0) {
							menu.children = [];
						}
					});
					return menuTree;
				}),
			});
			return {
				...toRefs(event),
			};
		},
	};
</script>

<style scoped></style>
