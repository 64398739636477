<template>
	<b-button
		v-b-tooltip.hover
		:class="isNavLinkActive(menuItem) ? 'active' : ''"
		:title="$t(menuItem.title)"
		:to="menuItem.route ? menuItem.route : ''"
		class="bg-light-secondary w-100 p-1 text-left d-flex flex-column justify-content-between"
		style="height: 6rem"
		variant="flat-primary"
	>
		<feather-icon :icon="menuItem.icon || 'GridIcon'" size="18" />

		<div class="w-100 text-nowrap text-truncate">
			{{ $t(menuItem.title) }}
		</div>
	</b-button>
</template>

<script>
	import { isNavLinkActive } from "@core/layouts/utils";

	export default {
		name: "TileItem",
		props: ["menuItem"],
		setup() {
			return {
				isNavLinkActive,
			};
		},
	};
</script>

<style scoped></style>
